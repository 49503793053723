<template>
	<div :class="[containerClass]">
		<ol :class="[listClass]">
			<li
				v-for="(item, index) in items.filter((i) => i.condition == null || i.condition)"
				:key="index"
				:class="[itemClass]"
			>
				<div class="flex items-center gap-2">
					<icon :icon="item.icon" class="flex-none min-w-8" v-if="item.icon"></icon>
					<div class="rounded-sm h-4 w-4" :class="item.color" v-if="item.color"></div>
					<div
						class="flex-1"
						:class="[
							item.class,

							{ 'flex justify-between items-center gap-2': canFlex(item) },
							{ 'space-y-2': !canFlex(item) },
							{ 'flex-wrap': canWrap(item) },
						]"
					>
						<div :class="item.labelClass">
							<p class="text-sm font-semibold">{{ item.label }}</p>
							<p class="text-sm" v-if="item.description">{{ item.description }}</p>
						</div>

						<div
							:class="[item.valueClass, { 'text-right': canWrap(item) }]"
							@click="item.link ? item.link(item) : null"
						>
							<a class="text-sm d-link d-link-primary inline-flex gap-2" v-if="item.link">
								{{ item.value }}
								<icon icon="chevron-right" cls="h-4 w-4 text-inherit"></icon
							></a>
							<p class="text-sm" v-else>{{ item.value }}</p>
						</div>
					</div>
				</div>

				<div :class="[itemClass]" v-if="item.detail">
					<p class="text-sm">{{ item.detail }}</p>
				</div>
			</li>
		</ol>

		<slot></slot>
	</div>
</template>

<script setup>
const props = defineProps({
	flex: {
		type: Boolean,
		default: true,
	},
	wrap: {
		type: Boolean,
		default: true,
	},
	items: {
		type: [Array, Object],
	},
	containerClass: {
		type: String,
	},
	itemClass: {
		type: String,
	},
	listClass: {
		type: String,
		default: 'divide-y',
	},
	itemClass: {
		type: String,
		default: 'py-3',
	},
})

const canFlex = (item) => {
	return props.flex == true && (item.flex == null || item.flex == true)
}

const canWrap = (item) => {
	return canFlex(item) && props.wrap == true && (item.wrap == null || item.wrap == true)
}
</script>
