import VueRouter from 'vue-router'
import routes from './routes.js'
import { useAuthStore } from '@/stores/auth'

const router = new VueRouter({
	routes,
})

router.beforeEach((to, from, next) => {
	const needsLogin = to.matched.some((route) => {
		if (useAuthStore().authenticated) return false
		if (!route.meta.permissions || route.meta.permissions.length == 0) return false

		return !route.meta.permissions.some((p) => {
			return useAuthStore().check(p)
		})
	})

	if (needsLogin) {
		return next({ name: 'login', query: { redirect: to.fullPath } })
	}

	return next()
})

export default router
