<template>
	<div class="VIEWS h-inherit flex">
		<div class="flex-1 bg-base-100">
			<component :is="rysqer.active.view.component" :stage="rysqer.stage" :view="rysqer.active.view"> </component>
			<splitpanes class="h-full w-full default-theme" push-other-panes>
				<!-- :class="{ hidden: rysqer.sizes[index] == 0 }" -->
				<pane
					v-for="(stage, index) in rysqer.stages"
					:key="stage.uuid"
					:size="rysqer.sizes[index]"
					class="PANE relative flex flex-col"
				>
					<div
						class="absolute inset-0 z-50 bg-base-100 bg-opacity-50 backdrop-blur-md flex flex-col justicy-center items-center"
						v-if="stage.busy"
					>
						<spinner class="flex-1"></spinner>
					</div>

					<div :class="[stage.hero.class]" v-if="stage.hero.title || stage.options?.closable">
						<div class="pt-6 pb-4 px-4">
							<div class="flex flex-wrap justify-between items-center gap-4">
								<div class="flex-1 flex flex-wrap justify-between items-start gap-2">
									<div>
										<div class="flex items-center gap-2">
											<p class="text-3xl font-bold">{{ stage.hero.title }}</p>

											<tippy
												v-if="stage.hero.menu && stage.hero.menu.items?.length"
												:ref="
													(el) => {
														refTippy = el
													}
												"
											>
												<template #trigger>
													<button class="d-btn d-btn-xs d-btn-circle">
														<icon icon="dots-horizontal" cls="w-4 h-4 text-primary"></icon>
													</button>
												</template>
												<ul class="min-w-52 d-menu w-full rounded-box">
													<li
														v-for="(item, index) in stage.hero.menu.items.filter(
															(i) => i.condition == null || i.condition == true
														)"
														:key="index"
													>
														<a
															@click="
																() => {
																	refTippy.tip.hide()
																	item.link()
																}
															"
														>
															<icon :icon="item.icon" :cls="item.iconClass">{{ item.label }}</icon>
														</a>
													</li>
												</ul>
											</tippy>

											<!-- <button class="d-btn d-btn-link" v-if="stage.hero.link" @click="stage.hero.link.click">
												{{ stage.hero.link.name }}
											</button> -->
										</div>

										<p class="pt-1 text-base" v-if="stage.hero.subtitle">{{ stage.hero.subtitle }}</p>
										<p class="pt-2 text-sm" v-if="stage.hero.description">{{ stage.hero.description }}</p>
									</div>
									<div class="flex items-center gap-2">
										<button
											v-if="stage.options?.closable"
											class="d-btn d-btn-sm d-btn-ghost d-btn-circle"
											@click="stage.options?.on?.close()"
										>
											<icon icon="close"></icon>
										</button>
									</div>
								</div>
								<portal-target :name="`stage-${index}-hero`" multiple></portal-target>
							</div>
						</div>
					</div>

					<div class="px-4 py-2 border-y" v-if="stage.scenes.length > 1">
						<rysqer-stage-tabs :stage="stage" />
					</div>

					<portal-target :name="`no-scroll-${index}`" multiple> </portal-target>

					<div
						class="SCENE relative flex-1 overflow-y-auto flex flex-col"
						:class="stage.scene.class"
						v-if="stage.scene"
						:key="stage.scene.id"
					>
						<component
							class="SCENE COMPONENT"
							:is="stage.scene.component"
							:key="stage.uuid + stage.scene.id"
							:stage="stage"
							:view="stage.scene.view"
							:scene="stage.scene"
							:options="stage.scene.options"
							:resources="stage.scene.resources"
						>
						</component>

						<component
							v-for="section in stage.scene.sectionsArray.filter((a) => {
								return (a.condition == null || a.condition == true) && (a.visible == null || a.visible == true)
							})"
							:key="stage.uuid + '-' + stage.scene.id + '-' + section.id"
							:class="['SECTION', section.class]"
							:is="section.component"
							:stage="stage"
							:view="stage.scene.view"
							:scene="stage.scene"
							:section="section"
							:options="{ ...stage.scene.options, ...section.options }"
							:resources="stage.scene.resources"
							:ref="(el) => (section.ref = el)"
						/>

						<!-- <rysqer-section
							v-for="section in stage.scene.sectionsArray.filter((a) => {
								return (a.condition == null || a.condition == true) && (a.visible == null || a.visible == true)
							})"
							class="SECTION"
							:key="stage.uuid + '-' + stage.scene.id + '-' + section.id"
							:stage="stage"
							:view="stage.scene.view"
							:scene="stage.scene"
							:section="section"
							:options="{ ...stage.scene.options, ...section.options }"
							:resources="stage.scene.resources"
						/> -->
					</div>
				</pane>
			</splitpanes>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, watch, inject } from 'vue'

import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'

import RysqerSection from '@/layout/Section/Section'

const rysqer = inject('rysqer')

const panes = ref()

const activePaneIndex = ref(-1) // No pane is active at start

const refTippy = ref(null)

function handlePaneFocus(index) {
	activePaneIndex.value = index
}

function handleResize(event) {
	rysqer.sizes.value = event.map((pane) => pane.size)
}
</script>

<style lang="scss">
.splitpanes.default-theme .splitpanes__splitter {
	@apply bg-inherit border border-base-200 bg-base-200 w-px mr-0 ml-0;
}

.splitpanes.default-theme .splitpanes__pane {
	@apply bg-inherit;
}
.splitpanes__pane {
	@apply overflow-y-hidden;
}

.splitpanes__splitter {
	// transition: none !important;
}
.splitpanes__pane {
	// transition: none !important;
}
// .splitpanes .splitpanes__pane {
// 	transition: none;
// }
</style>
