import moment from 'moment'
import _ from 'lodash'

export const capitalize = (value) => {
	if (!value) return ''
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
}

export const date = (value) => {
	if (!value) return ''
	return moment(String(value)).format('MMM DD, YY')
}

export const time = (value) => {
	if (!value) return ''
	return moment(String(value)).format('hh:mm a')
}

export const dateTime = (value) => {
	if (!value) return ''
	return moment(String(value)).format('MMM DD, YY hh:mm a')
}

export const utcLocalDateTime = (value) => {
	var utc = moment.utc(value).toDate()
	return moment(utc).local().format('MMM DD, YY hh:mm a')
}

export const dateTimeSent = (value) => {
	if (!value) return ''
	let date = moment(String(value))
	if (date.isSame(moment(), 'day')) return date.format('hh:mm a')
	else return moment(String(value)).format('DD. MMM YY hh:mm a')
}

export const longDateTime = (value) => {
	if (!value) return ''
	return moment(String(value)).format('ddd, MMM Do YYYY, h:mm:ss a')
}

export const quarter = (value) => {
	if (!value) return ''
	return moment(String(value)).quarter()
}

export const year = (value) => {
	if (!value) return ''
	return moment(String(value)).year()
}

export const trunc = (value, length = 30) => {
	if (!value) return ''
	return _.truncate(value, { length: length })
}

export const upperFirst = (value) => {
	if (!value) return ''
	return _.upperFirst(value)
}

export const lowerFirst = (value) => {
	if (!value) return ''
	return _.lowerFirst(value)
}

export const prettyJson = (value) => {
	return JSON.stringify(JSON.parse(value), undefined, 2)
}

export const prettyObject = (value) => {
	return JSON.stringify(value, undefined, 2)
}

export const prettyBytes = (num) => {
	if (!num) return ''
	if (typeof num !== 'number' || isNaN(num)) return ''

	var exponent
	var unit
	var neg = num < 0
	var units = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

	if (neg) {
		num = -num
	}

	if (num < 1) {
		return (neg ? '-' : '') + num + ' B'
	}

	exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1)
	num = (num / Math.pow(1000, exponent)).toFixed(2) * 1
	unit = units[exponent]

	return (neg ? '-' : '') + num + ' ' + unit
}
