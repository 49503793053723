<template>
	<div class="overflow-x-hidden" v-if="stage.scenes.length > 0">
		<div class="flex gap-3 overflow-x-auto" v-if="!stage.scene.options?.tabs?.tabless">
			<div
				:class="{ 'd-join': scene.options?.tabs?.closable }"
				v-for="scene in stage.scenes.filter(
					(s) => s.options?.tabs?.tabless == null || s.options?.tab?.tabless == false
				)"
				:key="scene.id"
			>
				<button
					class="d-btn d-btn-xs d-join-item"
					:class="['d-btn d-btn-sm', [scene.id === stage.scene.id ? 'd-btn-primary' : 'd-btn-link mx-1']]"
					@click="rysqer.stage.retab(stage.position, scene.label)"
				>
					{{ scene.label || scene.id }}
				</button>
				<button
					v-if="scene.options?.tabs?.closable"
					class="d-btn d-btn-xs d-join-item"
					:class="['d-btn d-btn-sm', [scene.id === stage.scene.id ? 'd-btn-primary' : 'd-btn-link mx-1']]"
					@click="rysqer.stage.untab(scene.id, stage.position)"
				>
					<icon icon="close" cls="h-4 w-4 text-inherit"></icon>
				</button>
			</div>
		</div>

		<div class="flex justify-between items-center gap-4" v-else>
			<p class="font-bold">{{ stage.scene.label }}</p>
			<button class="d-btn d-btn-sm" @click="rysqer.stage.untab(stage.scene.id, stage.position)">Back</button>
		</div>
	</div>
</template>

<script setup>
import { inject } from 'vue'

const rysqer = inject('rysqer')

const props = defineProps({
	stage: {
		type: Object,
		required: true,
	},
})
</script>
