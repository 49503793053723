import { defineStore } from 'pinia'
import moment from 'moment'

export const useFormattersStore = defineStore('rysqer-formatters-store', () => {
	const dateTime = (value) => {
		if (value == null) return '-'

		return moment(value).format('MMM DD, YY hh:mm a')
	}

	const date = (value) => {
		if (value == null) return '-'

		return moment(value).format('MMM DD, YY')
	}

	const utcLocalDateTime = (value) => {
		if (value == null) return '-'

		var utc = moment.utc(value).toDate()
		return moment(utc).local().format('MMM DD, YY hh:mm a')
	}

	const upperFirst = (value) => {
		return _.upperFirst(value)
	}

	const todaysTimeOrDate = (value) => {
		if (moment(value).isBefore(moment.now())) {
			return moment(value).format('MMM DD, YY')
		} else {
			return moment(value).format('hh:mm a')
		}
	}

	const htmlToText = (value) => {
		return value.replace(/<[^>]+>/g, '')
	}

	const ynd = (value) => {
		if (value == null) return '-'
		else if (!!value) return 'Yes'
		else return 'No'
	}

	const ynn = (value) => {
		if (value == null) return ''
		else if (!!value) return 'Yes'
		else return 'No'
	}

	return {
		dateTime,
		date,
		utcLocalDateTime,
		upperFirst,
		todaysTimeOrDate,
		htmlToText,
		ynd,
		ynn,
	}
})
