<template>
	<div class="rysqer-more d-collapse rounded-none overflow-visible">
		<input type="checkbox" name="rysqer-more" style="min-height: 0 !important" v-model="isExpanded" />
		<div class="d-collapse-title" style="padding: 0 !important; min-height: 0 !important">
			<div class="flex items-center gap-2">
				<icon :icon="icon" class="flex-none min-w-8" v-if="icon"></icon>
				<div class="rysqer-identifier" :class="identifierClass" v-if="identifier">
					<span>{{ identifier }}</span>
				</div>
				<div class="flex-1 flex justify-between items-center gap-4">
					<div>
						<p :class="[labelClass]">{{ label }}</p>
						<p :class="[descriptionClass]" v-if="description && !isExpanded" v-html="description"></p>
					</div>

					<div class="flex-1">
						<p :class="[metaClass]" v-if="meta">{{ meta }}</p>
						<slot name="meta"></slot>
					</div>

					<div class="flex-none flex items-center gap-2" :class="[valueClass]">
						<p v-if="value !== null">{{ value }}</p>
						<label class="d-swap">
							<input type="checkbox" v-model="isExpanded" />
							<div class="d-swap-on">
								<icon icon="chevron-up" cls="w-4 h-4 text-inherit"></icon>
							</div>
							<div class="d-swap-off">
								<icon icon="chevron-down" cls="w-4 h-4 text-inherit"></icon>
							</div>
						</label>
					</div>
				</div>
			</div>
		</div>
		<div class="d-collapse-content" style="padding: 0 !important">
			<div v-if="isExpanded" :class="[contentClass]">
				<slot name="default"></slot>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps({
	expanded: {
		type: Boolean,
		default: false,
	},
	identifier: {
		type: String,
	},
	identifierClass: {
		type: String,
	},
	icon: {
		type: String,
	},
	label: {
		type: String,
	},
	labelClass: {
		type: [Array, String],
		default: 'text-sm font-semibold',
	},
	meta: {
		type: String,
	},
	metaClass: {
		type: [Array, String],
	},
	description: {
		type: String,
	},
	descriptionClass: {
		type: String,
		default: 'text-sm line-clamp-1',
	},
	value: {
		type: [Number, String],
	},
	valueClass: {
		type: String,
		default: 'text-sm',
	},
	contentClass: {
		type: String,
		default: 'pt-4 text-sm',
	},
})

const isExpanded = ref(props.expanded)
</script>

<style lang="scss">
.rysqer-more.d-collapse {
	@apply static;
}
</style>
