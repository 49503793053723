<template>
	<div class="min-h-screen min-w-screen overflow-hidden">
		<!-- ------------------------------------------------- -->
		<!-- NAVBAR -->
		<!-- ------------------------------------------------- -->
		<div class="rysqer-layout--navigationbar-height flex justiy-between gap-6 w-full border-b">
			<div class="flex-1 flex items-center px-3 gap-6">
				<router-link :to="{ name: 'home' }" class="flex-none self-center">
					<img src="@/assets/images/rysqer.svg" class="w-28" />
				</router-link>

				<!-- <p class="text-3xl font-extrabold">rms</p> -->

				<div class="d-tabs" v-if="rysqer.active.app?.showTabs && rysqer.auth.authenticated">
					<a
						class="d-tab gap-2"
						:class="{ 'd-tab-active text-primary': view.id == rysqer.active.view.id }"
						v-for="view in Object.values(rysqer.active.app?.views).filter((v) => {
							return v.condition == null || v.condition == true
						})"
						:key="view.id"
						@click="rysqer.libs.router.push({ name: view.route }).catch(() => {})"
					>
						{{ view.label }}
						<span class="d-badge d-badge-secondary d-badge-sm" v-if="view.indicator">{{ view.indicator }}</span>
					</a>
				</div>

				<portal-target name="navbar"></portal-target>
			</div>
			<div class="flex-none px-3 flex items-center gap-4">
				<p class="font-bold">{{ rysqer.auth.user.name }}</p>
				<p class="hidden lg:block text-sm">{{ rysqer.auth.user.title }}</p>
				<button
					class="d-btn d-btn-link"
					@click="
						rysqer.auth.logout().then(() => {
							rysqer.libs.router.push({ name: 'login' })
							rysqer.libs.toast.success('Bye bye and thank you!')
						})
					"
					v-if="rysqer.auth.authenticated"
				>
					Logout
				</button>
			</div>
		</div>

		<!-- ------------------------------------------------- -->
		<!-- MAIN -->
		<!-- ------------------------------------------------- -->
		<div class="w-full relative LAYOUT_MAIN" :class="[appHeightClass]">
			<slot></slot>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, inject } from 'vue'

const rysqer = inject('rysqer')

const actionbar = ref(false)

const appHeightClass = computed(() => {
	return 'rysqer-layout--app-height'
})
</script>

<style lang="scss">
:root {
	--rysqer-layout--navigationbar-height: 4rem;
	--rysqer-layout--actionbar-height: 2rem;
}

.rysqer-layout--navigationbar-height {
	height: var(--rysqer-layout--navigationbar-height);
}

.rysqer-layout--app-height {
	height: calc(100vh - var(--rysqer-layout--navigationbar-height));
}
</style>
