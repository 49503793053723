<template>
	<div :class="section.class">
		<div class="d-collapse d-collapse-arrow" style="border-radius: 0 !important">
			<input type="checkbox" v-model="collapsed" />
			<div class="d-collapse-title flex items-center gap-3">
				<div
					v-if="(section.options?.collapsible?.symbol || section.options?.collapsible?.icon) && !collapsed"
					class="rysqer-identifier"
					:class="section.options?.collapsible?.color"
				>
					<span v-if="section.options?.collapsible?.symbol">{{ section?.options?.collapsible?.symbol }}</span>
					<icon
						v-if="section.options?.collapsible?.icon"
						:icon="section.options?.collapsible?.icon"
						cls="h-3 w-3 text-white"
					></icon>
				</div>
				<div>
					<p class="text-lg font-bold">{{ section.label }}</p>
					<p class="text-sm" v-if="section.options?.collapsible?.label">{{ section.options.collapsible.label }}</p>
				</div>
			</div>
			<div class="d-collapse-content">
				<section-top :section="section" />
				<component
					:is="section.component"
					:stage="stage"
					:view="view"
					:scene="scene"
					:section="section"
					:options="section.options"
					:resources="resources"
					:ref="(el) => (section.ref = el)"
				/>
				<section-bottom :section="section" />
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, inject } from 'vue'

import SectionTop from './Top'
import SectionBottom from './Bottom'

const props = defineProps({
	stage: {
		type: Object,
	},
	view: {
		type: Object,
	},
	scene: {
		type: Object,
	},
	section: {
		type: Object,
	},
	resources: {
		type: Object,
	},
})

const collapsed = ref(props.section.options?.collapsible.collapsed)

const rysqer = inject('rysqer')
</script>
