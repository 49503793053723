import { defineStore } from 'pinia'
import html2canvas from 'html2canvas-pro'
import jspdf from 'jspdf'

export const useLibsStore = defineStore('rysqer-libs-store', () => {
	const debounce = (fn, delay) => {
		var timer
		return function () {
			var args = arguments
			clearTimeout(timer)
			timer = setTimeout(() => {
				fn.apply(this, args)
			}, delay)
		}
	}

	const downloadAsPdf = async (querySelector, fileName = 'screenshot') => {
		const elem = document.querySelector(querySelector)
		html2canvas(elem, { scale: 12 }).then((canvas) => {
			const jpg = canvas.toDataURL('image/jpeg')

			const pdf = new jspdf({
				orientation: 'l',
				unit: 'pt',
				format: [canvas.width, canvas.height],
			})

			const MARGINS = 10

			pdf.addImage({
				imageData: jpg,
				format: 'JPEG',
				x: MARGINS,
				y: MARGINS,
				width: canvas.width - 2 * MARGINS,
				height: canvas.height - 2 * MARGINS,
				alias: null,
				compression: 'NONE',
				rotation: 0,
			})

			pdf.save(fileName + '.pdf')
		})
	}

	const downloadAsJpeg = async (querySelector, fileName = 'screenshot') => {
		const elem = document.querySelector(querySelector)
		html2canvas(elem, { scale: 12 }).then((canvas) => {
			const jpg = canvas.toDataURL('image/jpeg')

			const a = document.createElement('a')
			a.href = jpg
			a.download = fileName + '.jpeg'

			a.click()
		})
	}

	return {
		debounce,
		downloadAsPdf,
		downloadAsJpeg,
	}
})
